const links = [
  {
    label: 'About',
    url: '/about'
  },
  {
    label: 'Search',
    url: '/search'
  },
  {
    label: 'Tags',
    url: '/tags'
  }
]

export default links